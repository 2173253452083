<template>
    <section class="main-content-section">
        <LoadingSpinner v-if="isLoading" />
        <div v-else class="grid">
            <div v-if="order">
                <div class="col-8-12 push-2-12 md-col-10-12 md-push-1-12 sm-col-1-1 sm-push-0">
                    <div class="textpage search-order">
                        <div class="flex justify-space-between align-start back-btn-container">
                            <h2 class="page-title"><strong>{{reservation}}:</strong> {{order.number}}</h2>
                            <button @click="order = null" class="btn btn-primary light-grey small">{{back}}</button>
                        </div>

                        <div class="flex justify-space-between"><span class="bold">{{reservationDate}}</span><span>{{formattedDate}}</span></div>
                        <div class="flex justify-space-between"><span class="bold">{{reservationNumber}}</span><span>{{order.number}}</span></div>
                        
                        <br />
                        <hr />
                        <br />

                        <h4>{{personalInformation}}</h4>

                        <div class="flex justify-space-between"><span class="bold">{{name}}</span><span>{{order.user.name}}</span></div>
                        <div class="flex justify-space-between"><span class="bold">{{emailAddress}}</span><span>{{order.user.email}}</span></div>
                        <div class="flex justify-space-between"><span class="bold">{{phoneNumber}}</span><span>{{order.user.phone}}</span></div>
                        <div class="flex justify-space-between"><span class="bold">{{mobileNumber}}</span><span>{{order.user.mobile}}</span></div>

                        <br />    
                        <hr />
                        <br />

                        <div class="flex justify-space-between"><h4>{{reservedProducts}}</h4><span class="bold">{{quantity}}</span></div>

                        <ul>
                            <li v-for="line in order.lines" :key="line.id" class="flex justify-space-between">
                                <span class="bold">{{line[`name${selectedLanguage}`]}}</span>
                                <span>{{line.quantity}}</span>
                            </li>
                        </ul>

                        <br />
                        <hr />
                        <br />

                        <h4>{{pickUpLocation}}</h4>
                        
                        <div class="flex justify-space-between"><span class="bold">{{storeName}}</span><span>{{order.store.name}}</span></div>
                        <div class="flex justify-space-between"><span class="bold">{{address}}</span><span>{{order.store.address}}</span></div>
                        <div class="flex justify-space-between"><span class="bold">{{zipCode}}</span><span>{{order.store.zipCode}}</span></div>
                        <div class="flex justify-space-between"><span class="bold">{{city}}</span><span>{{order.store.city}}</span></div>
                        
                        <div class="flex justify-space-between search-order-btn-container">
                            <button @click="showCancelModal = true" class="btn btn-primary danger">{{cancelMyOrder}}</button>
                            <router-link :to="`/edit-order/${order.id}`" class="btn btn-primary">{{editOrder}}</router-link>
                            <button @click="resend()" class="btn btn-secondary">{{resendConfirmation}}</button>
                            <button @click="printOrderHandler()" class="btn btn-primary alternative">{{printOrder}}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
            <div class="col-1-1 center">
                <h2 class="page-title">{{lookupReservation}}</h2>
            </div>
            <div class="col-8-12 push-2-12 sm-col-1-1 sm-push-0">
                <div class="form-container register-form search clearfix">
                    <form @submit.prevent="submitForm()">
                        <div class="form-row flex flex-column col-8-12 push-2-12 sm-col-1-1 sm-push-0">
                            <p>{{findAnOrder}}</p>
                        </div>
                        <div class="form-row flex flex-column col-8-12 push-2-12 sm-col-1-1 sm-push-0">
                            <label for="email">{{emailAddress}}</label>
                            <input 
                                id="email" 
                                type="email"
                                v-model.trim="email"
                                @input="$v.email.$touch()"
                                :class="{ error: $v.email.$error }" 
                            />
                            <div v-if="$v.email.$dirty">
                                <p class="error" v-if="!$v.email.email">{{validEmailAddress}}</p>
                            </div>
                        </div>

                        <div class="form-row flex flex-column col-8-12 push-2-12 sm-col-1-1 sm-push-0">
                            <label for="orderNum">{{orderNumber}}</label>
                            <input 
                                type="text" 
                                id="orderNum" 
                                v-model.trim="orderNum"
                                @input="$v.orderNum.$touch()"
                                :class="{ error: $v.orderNum.$error }"
                            />
                            <div v-if="$v.orderNum.$dirty">
                                <p class="error" v-if="!$v.orderNum.valid">{{invalidSpecialCharacters}}</p>
                            </div>
                        </div>

                        <div class="form-row flex flex-column col-8-12 push-2-12 sm-col-1-1 sm-push-0">
                            <label for="phone">{{phoneNumber}}</label>
                             <input 
                                type="number" 
                                id="phone" 
                                v-model.trim="phone"
                                @input="$v.phone.$touch()"
                                :class="{ error: $v.phone.$error }"
                            />
                            <div v-if="$v.phone.$dirty">
                                <p class="error" v-if="!$v.phone.valid">{{invalidSpecialCharacters}}</p>
                            </div>
                        </div>

                        <div class="form-row flex flex-column col-8-12  push-2-12 sm-col-1-1 sm-push-0">
                            <button type="submit" class="btn btn-primary alternative">{{searchButton}}</button>
                        </div>
                    </form>
                </div>
            </div>

            </div>
        </div>
        <WarningMessageModal v-show="showWarningModal" :title="errorTitle" :text="errorMessage" @closeModal="closeModal()"/>
        <ConfirmationModal 
            v-show="showCancelModal" 
            @closeModal="showCancelModal = $event"
            @confirmed="cancelOrderHandler()" 
            :title="areYouSure" 
            :text="yourItemsWillNoLongerBeReserved" 
        />
    </section>
</template>

<script>
import LoadingSpinner from '../..//components/ui/LoadingSpinner.vue';
import WarningMessageModal from '../../components/modals/WarningMessageModal.vue';
import ConfirmationModal from '../../components/modals/ConfirmationModal.vue';
import { email } from 'vuelidate/lib/validators';
import {mapGetters} from 'vuex';
import agent from '../../api/agent';
import { downloadDocument } from '../../utils/downloadService';

export default {
    name: 'SearchOrder',
    components: {LoadingSpinner, WarningMessageModal, ConfirmationModal},
    data () {
        return {
            email: '',
            orderNum: '',
            phone: '',
            order: null,
            isLoading: false,
            errorTitle: '',
            errorMessage: '',
            showWarningModal: false,
            showCancelModal: false
        };
    },
    validations: {
        email: { email },
        orderNum: { 
            valid: (value) => {
                const containsSpecial = /[#?!@$%^&*-/";|<>+=\\(){}]/.test(value);
                return(
                    !containsSpecial
                );
            } 
        },
        phone: { 
            valid: (value) => {
                const containsSpecial = /[#?!@$%^&*-/";|<>+=\\(){}]/.test(value);
                return(
                    !containsSpecial
                );
            } 
        },
    },
    computed: {
        ...mapGetters([
            'selectedLanguage',
            // Texts 
            'emailAddress', 
            'validEmailAddress', 
            'orderNumber', 
            'searchButton', 
            'phoneNumber', 
            'lookupReservation',
            'findAnOrder',
            'reservation',
            'back',
            'reservationDate',
            'reservationNumber',
            'reservedProducts',
            'personalInformation',
            'name',
            'emailAddress',
            'phoneNumber',
            'mobileNumber',
            'quantity',
            'pickUpLocation',
            'storeName',
            'address',
            'zipCode',
            'city',
            'printOrder',
            'metaTitle',
            'resendConfirmation',
            'cancelMyOrder',
            'areYouSure',
            'yourItemsWillNoLongerBeReserved',
            'recaptchaValidation',
            'invalidSpecialCharacters',
            'editOrder'
        ]),

        formattedDate() {
            const rawDate = this.order.date.split('T');
            const date = rawDate[0];
            const time = rawDate[1].split('.')[0].split(':');
            return date + ' ' + time[0] + ':' + time[1];
        }
    },

    created() {
        this.setDocumentTitle();
        if (this.$route.query.orderId) {
            this.searchOrderById();
        }
    },
    
    methods: {
        setDocumentTitle() {
            document.title = this.lookupReservation + this.metaTitle;
        },

        showModal() {
            this.showWarningModal = true;
        },

        closeModal() {
            this.showWarningModal = false;
        },

        async searchOrder() {
            try {
                this.isLoading = true;
                this.order = await agent.service.searchOrder({
                    number: this.orderNum,
                    email: this.email.length > 0 ? this.email : null,
                    phone: this.phone.length > 0 ? this.phone : null
                });
                this.isLoading = false;
            } catch (error) {
                console.log(error.response);
                this.isLoading = false;
                this.errorTitle = error.response.data.title;
                this.errorMessage = error.response.data.detail;
                this.showModal();
            }
        },

        async searchOrderById() {
            try {
                this.isLoading = true;
                const result = await agent.order.details(this.$route.query.orderId);
                this.order = await agent.service.searchOrder({
                    number: result.number,
                    email: this.email,
                    phone: this.phone
                });
                this.isLoading = false;
            } catch (error) {
                console.log(error.response);
                this.isLoading = false;
                this.errorTitle = error.response.data.title;
                this.errorMessage = error.response.data.detail;
                this.showModal();
            }
        },

        async validateRecaptcha(token) {
            await this.$store.dispatch('validateRecaptcha', token);
            if (this.recaptchaValidation === true) {
                this.searchOrder();
            } else {
                this.isLoading = false;
                this.errorTitle = 'Something went wrong';
                this.errorMessage = 'Please try again';
                this.showModal();
            }
        },

        async getRecaptchaToken() {
            this.isLoading = true;
            await this.$recaptchaLoaded();
            const token = await this.$recaptcha('login');
            this.validateRecaptcha(token);
        },

        submitForm() {
            this.getRecaptchaToken();
        },

        async resend() {
            try {
                this.isLoading = true;
                await agent.service.resendConfirmation(this.order.number);
                this.isLoading = false;
            } catch (error) {
                console.log(error.response);
                this.isLoading = false;
            }
        },

        async printOrderHandler() {
            try {
                this.isLoading = true;
                const result = await agent.service.printOrder(this.order.number);
                this.isLoading = false;
                downloadDocument(result, 'pdf', `reservation-${this.order.user.name}`);
            } catch (error) {
                console.log(error.response);
                this.isLoading = false;
            }
        },

        async cancelOrderHandler() {
            this.isLoading = true;
            try {
                await agent.service.cancelOrder({orderId: this.order.id});
                this.order = null;
                this.isLoading = false;
                this.errorTitle = 'Order succesfully canceled';
                this.errorMessage = 'The order is now removed';
                this.showModal();
            } catch (error) {
                console.log(error.response);
                this.isLoading = false;
                this.errorTitle = error.response.data.title;
                this.errorMessage = error.response.data.detail;
                this.showModal();
            }
        }
    }
}
</script>

<style lang="scss">
.back-btn-container {
    margin-bottom: 5rem;
}

.search-order-btn-container {
    margin-top: 5rem;

    .btn.btn-primary,
    .btn.btn-secondary {
        font-size: 1.4rem;
        padding: 1rem 2rem;
        text-align: center;
    }
}

.bold {
    font-weight: 500;
}

@media only screen and (max-width: 1024px) {
    .form-container.register-form.search {
        padding: 6rem 0;
    }
}

@media only screen and (max-width: 768px) {
    .form-container.register-form.search {
        padding: 6rem;
    }

    .textpage.search-order {
        padding: 5rem;
    }

    .search-order-btn-container {
        .btn.btn-primary,
        .btn.btn-secondary {
            width: 45%;
            margin-bottom: 3rem;
            font-size: 1.8rem;
            padding: 1.5rem 2rem;
        }
    }

}

@media only screen and (max-width: 555px) {
    .back-btn-container {
        flex-direction: column;

        .page-title {
            order: 2;
        }

        .btn {
            align-self: flex-end;
            margin-bottom: 2rem;
        }
    }
}

@media only screen and (max-width: 500px) {
    .form-container.register-form.search {
        padding: 6rem 3rem;
    }

    .textpage.search-order {
        padding: 2rem;
    }

    .search-order-btn-container {
        .btn.btn-primary,
        .btn.btn-secondary {
            width: 48%;
                margin-bottom: 3rem;
                font-size: 1.4rem;
                padding: 1.8rem 2rem;
        }
    }
}

@media only screen and (max-width: 440px) {
    .textpage.search-order {
        font-size: 1.6rem;
    }

    .search-order-btn-container {
        .btn.btn-primary,
        .btn.btn-secondary {
            font-size: 1.2rem;
            padding: 1.5rem 2rem;
        }
    }
}

@media only screen and (max-width: 375px) {
    .search-order-btn-container {
        .btn.btn-primary,
        .btn.btn-secondary {
            font-size: 1rem;
            padding: 1.5rem 2rem;
        }
    }
}
</style>